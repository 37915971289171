


















































































































































import { Vue, Watch, Component } from 'vue-class-decorator'
import { GlobalModule, SettingsModule } from '@/store'
import { HTMLInputElement, Payload } from 'vue/interfaces'

@Component({
  metaInfo: {
    title: 'Настройки'
  }
})
export default class Settings extends Vue {
  public settingDialog: boolean
  public settingDialogTitle: string
  public tableSettings: Array<object>
  public selectedSetting: {
    id: string;
    title: string;
    content: string;
  }

  // Data
  constructor() {
    super()
    this.selectedSetting = {
      id: '0',
      title: '',
      content: ''
    }
    this.settingDialog = false
    this.settingDialogTitle = 'Создание'
    this.tableSettings = [
      {
        text: 'Название',
        value: 'title',
        align: 'start',
        sortable: false
      },
      {
        text: 'Содержимое',
        value: 'content',
        sortable: false
      },
      {
        text: 'Опции',
        value: 'setting_id',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @SettingsModule.Action('saveSetting') createSetting!: (payload: object) => Promise<void>
  @SettingsModule.Action('requestSettings') loadSettings!: () => Promise<void>
  @SettingsModule.Getter('getSettings') settings!: Array<object>

  // Watch
  @Watch('settingDialog') clearModalFields() {
    if (!this.settingDialog) {
      this.clearFields()
    }
  }

  //  Methods
  copySetting(id: number) {
    try {
      this.copyText(id)
      this.$noty('success', 'Текст скопирован в буфер обмена!', 7000).show()
    } catch (error) {
      this.$noty('error', 'Ваш браузер не поддерживает функцию копирования в буфер обмена!', 7000).show()
    }
  }

  copyText(id: number) {
    let range: any, selection: any
    const body = document.body as HTMLInputElement

    const copyText = document.getElementById(`copy-setting-${id}`)
    if (body.createTextRange) {
      range = body.createTextRange()
      range.moveToElementText(copyText)
      range.select()
    } else if (window.getSelection) {
      selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(copyText)
      selection.removeAllRanges()
      selection.addRange(range)
    }

    document.execCommand('copy')
    selection.empty()
  }

  codeCall(str: string) {
    return `<?=Setting::get('${str}')?>`
  }

  saveSetting() {
    const payload = {
      id: this.selectedSetting.id,
      title: this.selectedSetting.title,
      content: this.selectedSetting.content
    } as Payload
    this.createSetting(payload)
      .then(() => {
        this.$noty('success', 'Настройка сохранена!', 5000).show()
        this.settingDialog = false
        this.loadSettingsList()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  editSetting(id: number) {
    const setting = this.settings.find((s: any) => s.setting_id === id) as Payload
    if (setting) {
      this.selectedSetting = {
        id: setting.setting_id.toString(),
        title: setting.title,
        content: setting.content
      }
      this.settingDialogTitle = 'Редактирование'
      this.settingDialog = true
    } else {
      console.error(`Невозможно открыть настройку под ID: ${id}`)
    }
  }

  clearFields() {
    this.selectedSetting = {
      id: '0',
      title: '',
      content: ''
    }
    this.settingDialogTitle = 'Создание'
  }

  loadSettingsList() {
    this.loadSettings()
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  // Hooks
  created() {
    this.loadSettingsList()
  }
}
